<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" class="mx-1" :to="{ name: 'Student.Import' }"
        >Import From CSV</v-btn
      >
      <v-btn color="primary" class="mx-1" :to="{ name: 'Student.Create' }"
        >New Student</v-btn
      >
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Student</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="options.search"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="students"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.dateOfBirth`]="{ item: { dateOfBirth } }">
        {{ dateOfBirth | removeTime }}
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <v-tooltip v-if="id === '-'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-progress-question</v-icon>
            </v-btn>
          </template>
          <span>This student hasn't registered to any group</span>
        </v-tooltip>
        <div v-if="id !== '-'" style="width: 100px">
          <v-btn
            color="warning"
            :to="{ name: 'Student.Edit', params: { id } }"
            class="mt-1 mb-2"
            medium
            block
            >Edit</v-btn
          >
          <v-btn color="error" class="mb-1" @click="deletePrompt(id)" block
            >Delete</v-btn
          >
        </div>
      </template>
    </data-table>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteData()"
            :loading="loadingDelete"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Student from "@/services/student";

export default {
  name: "Student.Index",
  components: {
    DataTable
  },
  data() {
    return {
      totalData: 0,
      students: [],
      loading: true,
      loadingDelete: false,
      options: {
        search: undefined
      },
      headers: [
        { text: "Student ID", value: "id", width: "210px" },
        { text: "User", value: "userName", width: "210px" },
        { text: "E-mail", value: "userEmail", width: "150px" },
        { text: "Institute", value: "instituteName", width: "145px" },
        { text: "Group", value: "groupName", width: "160px" },
        { text: "School", value: "school", width: "160px" },
        { text: "Class", value: "class", width: "150px" },
        { text: "Exam Group", value: "examGroup", width: "120px" },
        { text: "Phone Number", value: "phoneNumber", width: "150px" },
        { text: "Major Option 1", value: "majorOption1", width: "250px" },
        { text: "Major Option 2", value: "majorOption2", width: "250px" },
        { text: "Parents' Name", value: "parentsName", width: "210px" },
        {
          text: "Parents' Phone Number",
          value: "parentsPhoneNumber",
          width: "150px"
        },
        { text: "Parents' Email", value: "parentsEmail", width: "150px" },
        { text: "Date of Birth", value: "dateOfBirth", width: "130px" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: ""
    };
  },
  watch: {
    options: {
      handler() {
        this.getStudents();
      },
      deep: true
    }
  },
  filters: {
    removeTime(datetime) {
      if (datetime === null) {
        return datetime;
      }

      return datetime.slice(0, -14);
    }
  },
  created() {
    this.setNavbarTitle("Manage Student");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getStudents() {
      this.loading = true;
      this.students = [];
      const students = await Student.index(this.options);
      this.students = students.data.data.students;
      this.totalData = students.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await Student.delete(this.selectedId);
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        this.loadingDelete = false;
      }
    }
  }
};
</script>
